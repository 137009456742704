import { type Injector } from '@angular/core';
import { type SdsUserModelV2 } from '@sds/api/administration/models';
import { BaseWidget } from '@sds/widget-gridster';

export class Enerest2GoWebviewWidget extends BaseWidget<{ user: SdsUserModelV2 }> {
  static readonly id = 'enerest2go-webview';
  static readonly publicFriendly = true;

  static factory(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data: { user: SdsUserModelV2 }
  ) {
    return new Enerest2GoWebviewWidget(injector, metaLinkId, x, y, rows, cols, data);
  }

  constructor(
    injector: Injector,
    metaLinkId: string,
    x: number,
    y: number,
    rows: number,
    cols: number,
    data?: { user: SdsUserModelV2 }
  ) {
    super(
      injector,
      Enerest2GoWebviewWidget.id,
      Enerest2GoWebviewWidget.publicFriendly,
      metaLinkId,
      x,
      y,
      rows,
      cols,
      data
    );
    this.resolveComponent(async () => {
      const module = await import('./enerest2go-webview.component');
      return Object.values(module)[0];
    });
  }

  override resolveData(data: { user: SdsUserModelV2 }): void {
    this.setData({ user: data.user });
  }
}
